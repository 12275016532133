import * as React from "react";
import BoxContainer from "components/BoxContainer";
import Text from "components/Text";
import IsoCertified from "components/IsoCertified";

const CertificationSamples = ({ data }) => {
  return (
    <>
      <BoxContainer background="bg-white" className="items-center py-8">
        <div className="mt-8 md:w-10/12 2xl:max-w-6xl m-auto">
          <div className="md:grid grid-cols-2 items-center ">
            <div className="hidden md:block md:py-12 pr-8">
              <img
                src={data?.imgurl}
                alt="business"
                className="rounded-[4px]"
              />
            </div>
            <div className="flex flex-col pl-5">
              <div className="pb-12">
                <Text
                  className="font-semibold text-3xl2 text-midBlue font-secondary text-center sm:text-left"
                  as="h2"
                  highlightedText={data.highlightWords}
                  highlightedColor={data.highlightedColor}
                >
                  {data?.title}
                </Text>
                <Text
                  className="mb-8 md:mb-0 mt-8 text-midBlue leading-8 font-opensans text-lg text-center sm:text-left"
                  as="div"
                >
                  {data?.description}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </BoxContainer>
      {data.isoCertifiedData_status && (
        <IsoCertified
          data={data.isoCertifiedData}
          background="bg-gradient-to-t from-gradient1 to-white"
        />
      )}
    </>
  );
};
export default CertificationSamples;
